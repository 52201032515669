<template>
  <div>
    <WLHYbulkImport />
  </div>
</template>
<script>
import WLHYbulkImport from "@/views/boss/transport/driverManage/WLHYbulkImport";
export default {
  data() {
    return {

    }
  },
  components: {
    WLHYbulkImport,
  },
}
</script>